<template>
  <div class="text-left">
    <!-- <BaseHeader :title="'Profile'"></BaseHeader> -->
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="$store.state.loading"
    ></BaseLoading>

    <div class=" mt-4" v-else>
      <div class="t-page-container   ">
        <BaseAsidebar></BaseAsidebar>
        <div  class="t-page-fluidcol">
          <div class="t-page-container t-page-container_inside">
            <div class="t-page-fluidcol t-page-fluidcol_inside">
            <div class="white-block personal-info-block">
            <h3 class="subtitle-h3 d-flex  mb20">
            <span class="icon-user "></span> <p class="px-1" >Personal information</p>  </h3>
            <form @submit.prevent="updateProfile" id="profileForm" class="personal-info-form" > <div class="row">
            <div class="col-md-6 col-xs-12">
            <div class="form-group field-profileform-firstname required">
            <label class="control-label" for="profileform-firstname">First name</label>
            <input type="text" v-model="registerForm.first_name" id="profileform-firstname" class="form-control" name="ProfileForm[firstName]" value="" data-field="firstName" aria-required="true">
            </div> </div>
            <div class="col-md-6 col-xs-12">
              <div class="form-group field-profileform-lastname required">
              <label class="control-label" for="profileform-lastname">Last name</label>
              <input type="text" v-model="registerForm.last_name" id="profileform-lastname" class="form-control" name="ProfileForm[lastName]" value="" data-field="lastName" aria-required="true">
              </div> 
            </div>
            </div>

            <div class="row">
            <div class="col-md-6 col-xs-12">
            <div class="form-group field-profileform-email required">
            <label class="control-label" for="profileform-email">Email</label>
            <input type="text"  v-model="registerForm.email" id="profileform-email" class="form-control" name="ProfileForm[email]" value="lessin915@gmail.com" placeholder="Email" data-field="email" aria-required="true">
            </div> 
            </div>
            <div class="col-md-6 col-xs-12">
              <div class="form-group field-profileform-lastname required">
              <label class="control-label" for="profileform-lastname">Phone Number</label>
              <input type="text"  v-model="registerForm.phone" id="profileform-phone" class="form-control" name="ProfileForm[phone]" value="" data-field="phoneNumber" aria-required="true">
              </div> 
            </div>

            </div>

            <div class="row">
            <div class="col-md-6 col-xs-12">
            <div class="form-group field-profileform-email required">
            <label class="control-label" for="profileform-email">Country</label>
            <v-select
              label="name"
              v-model="registerForm.country"
              placeholder="Start typing to search"
              :options="countryNames"
              :reduce="(country) => country"
            ></v-select>
            
            </div> 
            </div>
          

            </div>


            <div class="upd-btn">
            <button type="submit" :disabled="registerForm.busy" class="first-btn">
            Update </button>
            </div>
            </form> 
            </div>

            </div>


            <div class="t-page-rightbar t-page-rightbar_password">
          <div class="white-block t-change-password">
          <h3 class="subtitle-h3 d-flex mb20">
          <span  class="icon-lock px-1"></span>
          <p>Password change</p>  </h3>
          <form id="w0" class="t-change-password__form" @submit.prevent="changePassword">
          <!-- <div class="form-group field-changepasswordform-currentpassword required">
          <label class="control-label" for="changepasswordform-currentpassword">Current password</label>
          <input type="password" id="changepasswordform-currentpassword" class="form-control" name="ChangePasswordForm[currentPassword]" placeholder="Current password" aria-required="true">
          <span class="help-block"></span>
          </div> -->
          <div class="form-group field-changepasswordform-newpassword required">
          <label class="control-label" for="changepasswordform-newpassword">New password</label>
          <input type="password"  v-model="passwordForm.password" id="changepasswordform-newpassword" class="form-control" name="ChangePasswordForm[newPassword]" required placeholder="New password" aria-required="true">
          <span class="help-block"></span>
          </div>
          <div class="form-group field-changepasswordform-confirmnewpassword required">
          <label class="control-label" for="changepasswordform-confirmnewpassword">Repeat new password</label>
          <input type="password" v-model="passwordForm.password_confirmation" required id="changepasswordform-confirmnewpassword" class="form-control" name="ChangePasswordForm[confirmNewPassword]" placeholder="New password again" aria-required="true">
          <span class="help-block"></span>
          </div>
          <div class="upd-btn">
          <input type="submit" class="first-btn" value="Update"> </div>
          </form> </div>
          <a style="cursor:pointer!important" class="t-logout__btn t-logout__btn_hover text-light-grey"  @click="doLogout">
            <span class="icon-switch2 px-2" ></span>
            <span>Sign out</span></a> </div>
          </div>
          
        </div>
        
      </div>
     



    </div>

    <BaseOverlay v-if="is_loading"></BaseOverlay>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { countries } from "countries-list";

export default {
  name: "Profile",
  computed: {
    countryNames() {
      return Object.keys(countries).map((country) => countries[country].name);
    },
    ...mapState("auth", ["user"]),
    user_name() {
      let first = this.user.first_name || "";
      let last = this.user.last_name || "";
      return first + " " + last;
    },
  },
  data() {
    return {
      is_loading: false,
      registerForm: new this.$Form({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country: "",
        password: "",
        password_confirmation: "",
      }),
      passwordForm: new this.$Form({
        password: "",
        password_confirmation: "",
      }),
    };
  },
  mounted() {
    Object.assign(this.passwordForm, { ...this.registerForm });

    this.$store.state.loading = true;
    this.doGetAuthUser()
      .then(() => {
        Object.assign(this.registerForm, { ...this.user });
      })
      .catch((err) => console.log(err))
      .finally(() => (this.$store.state.loading = false));
    this.registerForm.busy = false;
  },
  methods: {
    encodePassword(password) {
      return window.btoa(unescape(encodeURIComponent(password)));
    },
    ...mapActions("auth", ["doLogout"]),


    ...mapActions("auth", [
      "doUpdateProfile",
      "doChangePassword",
      "doGetAuthUser",
    ]),

    changePassword() {
      this.passwordForm.first_name = this.registerForm.first_name;  
      this.passwordForm.last_name = this.registerForm.last_name;  
      this.passwordForm.email = this.registerForm.email;  
      this.passwordForm.phone = this.registerForm.phone;  
      this.passwordForm.country = this.registerForm.country;  
      if (
        this.passwordForm.password &&
        this.passwordForm.password === this.passwordForm.password_confirmation
      ) {
        this.is_loading = true;
        this.passwordForm.password = this.encodePassword(
          this.passwordForm.password
        );
        this.passwordForm.password_confirmation = this.encodePassword(
          this.passwordForm.password_confirmation
        );

        console.log("password form", this.passwordForm);

        this.doChangePassword(this.passwordForm)
          .then((res) => {
            console.log("change passres", res);
            this.$notify({
              title: "Success",
              text: "Password updated successifully!",
            });
            this.passwordForm.password = "";
            this.passwordForm.password_confirmation = "";
          })
          .catch((err) => {
            this.$notify({
              title: "Error",
              text: err.response.data ? err.response.data.Message : "Error",
              style: "danger",
            });
          })
          .finally(() => (this.is_loading = false));
      } else
        this.$notify({
          title: "Error",
          text: "Passwords do not match",
          style: "danger",
        });
    },

    updateProfile() {
      this.is_loading = true;
      delete this.registerForm.password;
      delete this.registerForm.password_confirmation;
      console.log(this.registerForm);
      this.doUpdateProfile(this.registerForm)
        .then(() => {
          this.$notify({
            title: "Success",
            text: "Profile updated successifully!",
          });
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => (this.is_loading = false));
    },
  },
};
</script>
